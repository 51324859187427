import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'

const pinia = createPinia()
const vueApp = createApp(App)
vueApp.config.devtools = true // !!! ATTENTION remove in production
vueApp.use(router)
vueApp.use(pinia)
vueApp.mount('#app')