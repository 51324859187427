<template>
  <default-layout>
    <div>
      Sign In Page <br>
      <button @click="signIn">Google Sign In</button>
      <!-- <iframe src="https://www.googleapis.com/drive/v3/files/18usTgdkI7LRo1-AniVcWgiRrHcWm-LxT?alt=media&key=AIzaSyA8d5YsPdeFIn9wD0ABoyRLdtc4R2e4HbE" width="640" height="480" allow="autoplay"></iframe> -->
      <!-- <video controls>
        <source src="https://www.googleapis.com/drive/v3/files/18usTgdkI7LRo1-AniVcWgiRrHcWm-LxT?alt=media&key=AIzaSyA8d5YsPdeFIn9wD0ABoyRLdtc4R2e4HbE" type="video/mp4">
      </video> -->

    </div>
  </default-layout>
</template>

<script>
import {getAuth, GoogleAuthProvider, signInWithPopup} from 'firebase/auth'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import { useRouter } from 'vue-router'
// import { ref } from 'vue';


export default {
  setup() {
    const router = useRouter()

    const signIn = async () => {
      const provider = new GoogleAuthProvider()
      const auth = getAuth();

      return await signInWithPopup(auth, provider)
      .then( (result) => {
                          // This gives you a Google Access Token. You can use it to access the Google API.
                          GoogleAuthProvider.credentialFromResult(result);
                          // const credential = GoogleAuthProvider.credentialFromResult(result);
                          // const token = credential.accessToken;

                          // Use the token to search for a file in Google Drive
                          //const query = encodeURI('mimeType="application/vnd.google-apps.document" and trashed = false and name contains "example"');
                          // const url = `https://www.googleapis.com/drive/v3/files/1Vr-Oxy9AF410ZrPe0Ms7x_Uft0j9DwEU`;

                          // fetch(url, {
                          //   method: "GET",
                          //   headers: {
                          //     "Authorization": "Bearer " + token,
                          //   }
                          // })
                          // .then(response => response.json())
                          // .then(data => {
                          //   console.log(data);
                          //   // data.files will contain an array of all the files matching the query
                          // })
                          // .catch(error => console.error(error));
                        })
        .then(() => router.replace({ name: 'Inbox' }))
    }


    return {
      signIn,
    }
  },
  components: { DefaultLayout },
}
</script>