import { defineStore } from 'pinia';
import { useLocalStorage } from "@vueuse/core"

export const useStateStore = defineStore('stateStore', {
    state: () => ({
        selectedLocation: useLocalStorage('selectedLocation', null),
        userLocations: useLocalStorage('userLocations', {}),
    }),
    actions: {
        appendToUserLocations(key, value) {
            this.userLocations[key] = value
        },
        clearStore(){
            this.zone = 0;
            this.selectedLocation = null;
            this.userLocations = {};
        },
        getSelectedLocationId(){
            return this.userLocations[this.selectedLocation];
        },
    },  
})

