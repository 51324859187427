import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AccountView from '../views/AccountView.vue'
import SignInView from '../views/SignInView.vue'
import RegisterView from '../views/RegisterView.vue'
import InboxView from '../views/InboxView.vue'
import ConfirmedDetectsView from '../views/ConfirmedDetectsView.vue'
import AdminHomeView from '../views/AdminHomeView.vue'
import { getCurrentUser, checkAdmin} from '@/firebase'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/account',
    name: 'Account',
    component: AccountView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: SignInView,
    meta: {
      guestOnly: true,
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterView,
    meta: {
      guestOnly: true,
    }
  },
  {
    path: '/inbox',
    name: 'Inbox',
    component: InboxView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/confirmed_detections',
    name: 'Confirmed',
    component: ConfirmedDetectsView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/admin_home',
    name: 'AdminHome',
    component: AdminHomeView,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin)
  const isGuestOnly = to.matched.some(record => record.meta.guestOnly)

  const currentUser = await getCurrentUser()
  const isAdmin = await checkAdmin()

  if (requiresAuth && !currentUser) {
    next('/signin')
  } else if (isGuestOnly && currentUser) {
    next('/account')
  } 
  else if (requiresAdmin && !isAdmin){
    next('/inbox')
  }
  else {
    next()
  }
})

export default router
