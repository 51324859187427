import { getApps, initializeApp } from "firebase/app"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { getStorage } from "firebase/storage"
import {
  getFirestore,
} from "firebase/firestore";
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyA8d5YsPdeFIn9wD0ABoyRLdtc4R2e4HbE",
  authDomain: "tailgating-detection.firebaseapp.com",
  projectId: "tailgating-detection",
  storageBucket: "tailgating-detection.appspot.com",
  messagingSenderId: "686948867200",
  appId: "1:686948867200:web:c8d3755ed8d6d64ebf1a65"
};

const apps = getApps()
const firebaseApp = !apps.length ? initializeApp(firebaseConfig) : apps[0]
const firebaseAuth = getAuth(firebaseApp)
const firebaseStorage = getStorage(firebaseApp);
const firestoreDb = getFirestore(firebaseApp);
const firebaseFunctions = getFunctions(firebaseApp);

const getCurrentUser = () => new Promise((resolve, reject) => {
    const unsub = onAuthStateChanged(firebaseAuth, user => {
        unsub()
        resolve(user)
    }, reject)
})



// const checkAdmin = async () => new Promise((resolve, reject) => {
//     const unsub = onAuthStateChanged(firebaseAuth, async user => {
//       if (user) {
//         const usersRef = collection(firestoreDb, "users");
//         const usersQuery = query(usersRef, where("email", "==", user.email), limit(1));
//         const usersQuerySnap = await getDocs(usersQuery);
//         let docData = null;
//         usersQuerySnap.forEach((doc) => {
//           // doc.data() is never undefined for query doc snapshots
//           docData = doc.data();
//           // console.log(doc.id, " => ", docData);
//         });

//         if(docData.isAdmin){
//           resolve(true);
//         }
//         else{
//           resolve(false);
//         }
//       } 
      
//       else {
//         // reject(new Error("User not signed in"));
//         console.log("use not signed in");
//         resolve(false);
//       }

//       unsub()
//     }, reject)
// })

const checkAdmin = () => {
  if(firebaseAuth.currentUser){
    return firebaseAuth.currentUser.getIdTokenResult()
    .then((idTokenResult) => {
      // Confirm the user is an Admin.
      if (idTokenResult.claims.admin) {
        // Show admin UI.
        return true;
      } else {
        // Show regular user UI.
        return false;
      }
    })
  }
}



export { firebaseApp, firebaseAuth, firebaseStorage, firestoreDb, firebaseFunctions, getCurrentUser, checkAdmin }