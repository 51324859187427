<template>
  <div>
    <NavBar />
    <!-- <h1 class="text-3xl font-semibold">Detect Unauthorised Entry</h1>
    <p>AI security uses state of the art computer vision software to help businesses keep unwanted visitors out of their premises</p>
    <p> Why door access control is not enough...</p>
    <p> Applications include analysing movement patterns to find tailgaiters</p> -->

    <!-- HTML markup for the "Coming Soon" page -->
    <div class="h-[calc(100vh-96px)] flex items-center bg-[url('@/assets/windows.jpg')] bg-auto">
      <div class="container mx-auto px-4">
        <div class="max-w-md mx-auto text-center">
          <h1 class="text-5xl font-bold font-serif bg-black bg-opacity-20">Coming Soon</h1>
          <div class="mt-8 flex justify-center">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";

export default {
  components: { 
    NavBar 
  },
  setup() {

  }
};
</script>