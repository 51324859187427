<template>
  <div>
    <h1 v-if="Object.keys(videos_obj).length != 0"
        class="border-b text-cyan-50">
      {{path.split("/")[path.split("/").length-1]}}
    </h1>
    <div class="grid-container">
      <!-- <div v-for="video in Object.keys(videos_obj)" :key="video"> -->
      <div v-for="value, key in videos_obj" :key="key">
        <div class="grid-item bg-transparent">
            <VideoSection 
              :vidSrc="`${key}`"
              :vidInfoPath="replaceMp4withJson(value)"
              :width="width"
              :height="height"
              :mode="mode" 
              :removeVidFromView="removeVidFromView"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
//import { firebaseAuth } from "@/firebase";
import { getStorage, ref as ref_storage, getDownloadURL, listAll } from "firebase/storage";
import VideoSection from '@/components/VideoSection.vue';

export default {
  props: ['path', 'mode', 'width', 'height', 'moveFile'],
  components: {
    VideoSection,
  },
  setup(props) {
    const videos_obj = reactive({});
    const videos_infos = reactive({});

    const url = ref()
    const loading = ref();
    loading.value = true;

    const fetchFiles = () => {
      loading.value = true;
      const storage = getStorage();
      
      const listPath = props.path;
      // const listPath = "gs://tailgating-detection.appspot.com/" + firebaseAuth.currentUser.uid + "/suspect_clips/inbox/"
      const listRef = ref_storage(storage, listPath);
      // Find all the prefixes and items.
      listAll(listRef)
        .then((res) => {
          res.items.forEach((itemRef) => {
            // All the items under listRef.
            const vidPath = "gs://" + itemRef.bucket + "/" + itemRef.fullPath
            const vidRef = ref_storage(storage, vidPath);

            if (vidRef.fullPath.toLowerCase().endsWith(".mp4")){
              getDownloadURL(vidRef)
              .then( (url) => {
                  videos_obj[url] = vidPath;
                }
              )              
            }

            else if (vidRef.fullPath.toLowerCase().endsWith(".json")){
              getDownloadURL(vidRef)
              .then( async (url) => {
                  const response = await fetch(url);
                  const data = await response.json();
                  videos_infos[vidRef.fullPath.replace(".json", ".mp4")] = data
                }
              )
            }

          });
        })
        .catch((error) => {
          console.log(error.message)
        });

        // !!! ATTENTION implement a promise instead
        setTimeout(() => {
          loading.value = false
        }, 1200)
    }

    // if (Object.keys(videos_obj).length == 0){
    //   console.log("it's empty")
    // }
      
    const removeVidFromView = (vid, type) => {
      const vidPath = videos_obj[vid]
      const vidInfoJson = vidPath.replace(".mp4", ".json")
      
      props.moveFile(vidPath, type)    // Moves the file to the approprite folder on firebase storage depending on whether it is a positive or false positive
      props.moveFile(vidInfoJson, type)

      delete videos_obj[vid] // Removes the video from dictionary so it's no longer displayed on front end
    }
    
    const replaceMp4withJson = (string) => {
      return string.replace(".mp4", ".json");
    };

    onMounted(() => {
      loading.value = true;
      fetchFiles();
    });


    return {
      fetchFiles,
      url,
      videos_obj,
      videos_infos,
      removeVidFromView,
      replaceMp4withJson,
      loading
    }
  }
};
</script>

<style>
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
}
.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  text-align: center;
}
</style>
