<template>
  <!-- Raw vid size: 3072 x 1728 -->
  <div>
    <video 
    :src="`${vidSrc}#t=1.5`" 
    :width="width" 
    :height="height"
    preload="metadata"
    controls
    muted
    ></video>

    <div>
      <table>
        <thead>
          <tr>
            <th class="border">Card No.</th>
            <th class="border">Name</th>
            <th class="border">Date</th>
            <th class="border">Door</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(value, key) in jsonData['report']" :key="key">
            <td class="border px-4 py-2">{{ key }}</td>
            <td class="border px-4 py-2">{{ value[0] }}</td>
            <td class="border px-4 py-2">{{ value[1] }}</td>
            <td class="border px-4 py-2">{{ value[2] }}</td>
          </tr>
        </tbody>
      </table>
      <br/>
      <!-- <table>
        <thead>
          <tr>
            <th class="border">Id</th>
            <th class="border">Move Classification</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(value, key) in jsonData['movementCat']" :key="key">
            <td class="border px-4 py-2">{{ key }}</td>
            <td class="border px-4 py-2">{{ value }}</td>
          </tr>
        </tbody>
      </table> -->

    </div>

    <div v-if="mode === 'inbox'" class="space-x-2">
      <h6> Unauthorised entry? </h6>
      <button v-if= !confirmed @click="handlePositiveRes"
              class="bg-emerald-600 hover:bg-emerald-800 text-white font-bold py-1 px-4 rounded">
              Yes
      </button>
      <button v-if= !confirmed @click="handleFalsePositiveRes"
              class="bg-rose-600 hover:bg-rose-800 text-white font-bold py-1 px-4 rounded">
              No
      </button>
    </div>    
  </div>

</template>

<script>
import { ref, onMounted } from "vue";
import { getStorage, ref as ref_storage, getDownloadURL, } from "firebase/storage";

export default {
  props: ['vidSrc', 'vidInfoPath', 'width', 'height', 'mode', 'removeVidFromView'], //props.vidInfoPath
  setup(props) {
    var confirmed;
    const jsonData = ref({});

    const loadJsonData = async () => {
      const jsonRef = ref_storage(getStorage(), props.vidInfoPath);
      getDownloadURL(jsonRef)
      .then( async (url) => {
          const response = await fetch(url);
          const data = await response.json();
          jsonData.value = data;
        }
      )
      .catch((error) => {
          // Handle any errors
          console.error('Error getting video info JSON download URL:', error);
        });
    };

    const handlePositiveRes = () => {
      console.log("Posititve: " + props.vidSrc);
      props.removeVidFromView(props.vidSrc, "confirmed");
      confirmed = true;
    }

    const handleFalsePositiveRes = () => {
      console.log("False Posititve: " + props.vidSrc);
      props.removeVidFromView(props.vidSrc, "false_positive");
      confirmed = true;
    }

    onMounted(() => {
      confirmed = false;
      loadJsonData();
    });


    return {
      jsonData,
      handlePositiveRes,
      handleFalsePositiveRes,
      confirmed,
    }
  }
};
</script>