<template>
  <default-layout>
    <video-feed mode="inbox"></video-feed>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout";
import VideoFeed from "@/components/VideoFeed";

export default {
  components: { 
    DefaultLayout,
    VideoFeed
  },

  setup() {
 
    return {

    }
  }
};
</script>