<template>
  <div class="bg-theme-teriary">
    <NavBar />

    <div class="container mt-3">
      <slot />
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
export default {
  components: { NavBar },
};
</script>