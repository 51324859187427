<template>
  <header class="sticky top-0 bg-theme-primary shadow-lg">
    <nav
      class="flex flex-col sm:flex-row items-center gap-11 text-white py-6 m-0 p-16 bg-gradient-to-r from-purple-600 via-blue-400 to-orange-400"
    >
      <div class="block mt-4 lg:inline-block lg:mt-0 text-white mr-4 hover:text-teal-200">
        <RouterLink :to="{ name: 'Home' }">
          <div class="flex items-center gap-3 justify-start">
            <p class="text-5xl font-semibold font-serif">Fusor Labs</p>
          </div>
        </RouterLink>
      </div>

      <div  v-if="loggedIn" 
            class="gap-3 flex-1 w-full block flex-grow lg:flex lg:items-center lg:w-auto">
        <div  :class="{ 'text-teal-200 font-bold text-xl border-b border-teal-200': route == '/inbox' }"
              class="block mt-4 lg:inline-block lg:mt-0 text-white mr-4 hover:text-teal-200">
          <RouterLink :to="{ name: 'Inbox' }">
            <div class="flex items-center gap-3">
              <p class="text-l">Inbox</p>
            </div>
          </RouterLink>       
        </div>

        <div  :class="{ 'text-teal-200 font-bold text-xl border-b border-teal-200': route == '/confirmed_detections' }"
              class="block mt-4 lg:inline-block lg:mt-0 text-white mr-4 hover:text-teal-200">
          <RouterLink :to="{ name: 'Confirmed' }">
            <div class="flex items-center gap-3">
              <p class="text-l">Confirmed</p>
            </div>
          </RouterLink>        
        </div>

        <div  :class="{ 'text-teal-200 font-bold text-xl border-b border-teal-200': route == '/account'}"
              class="block mt-4 lg:inline-block lg:mt-0 text-white mr-4 hover:text-teal-200">
          <RouterLink :to="{ name: 'Account' }">
            <div class="flex items-center gap-3">
              <p class="text-l">Account</p>
            </div>
          </RouterLink>        
        </div>

      </div>
      

      <div class="flex gap-3 flex-1 justify-end">
        <div v-if="loggedIn">
          <DropdownMenu :items="dropdownItems" :handleSelect="changeLocation"></DropdownMenu>
        </div>
        
        <button v-if="!loggedIn"
                class="bg-transparent hover:text-teal-200 text-blue-700 font-semibold py-2 px-4 border border-blue-500 hover:border-teal-200 rounded"
                @click="register">
          Register
        </button>
        
        <button v-if="!loggedIn"
                class="bg-transparent hover:text-teal-200 text-blue-700 font-semibold py-2 px-4 border border-blue-500 hover:border-teal-200 rounded"
                @click="signIn">
          Sign In
        </button>

        <button v-if="loggedIn"
                class="bg-transparent hover:text-teal-200 text-blue-700 font-semibold py-2 px-4 border border-blue-500 hover:border-teal-200 rounded" 
                @click="logOut">
          Log out
        </button>
      </div>
    </nav>
  </header>
</template>

<script>
import { signOut, getAuth, GoogleAuthProvider, signInWithPopup} from 'firebase/auth'
import {
  collection,
  getFirestore,
  getDocs,
  where,
  query,
  limit,
} from "firebase/firestore";
import { firebaseAuth, checkAdmin } from "@/firebase";
import { ref, onMounted,} from 'vue';
import { useRouter, useRoute} from 'vue-router'
import DropdownMenu from './DropdownMenu.vue';
import { useStateStore } from '@/stores/StateStore';

export default {
  components: {
    DropdownMenu,
  },
  setup() {
    const stateStore = useStateStore();
    const dropdownItems = ref([]);

    const router = useRouter();
    const route = ref();
    route.value = useRoute().path

    const currUser = ref(firebaseAuth.currentUser);
    const isRegistered = ref(false);
    const loggedIn = ref(false);
    loggedIn.value = currUser.value !== null ? true : false;

    const signIn = async () => {
      const provider = new GoogleAuthProvider()
      const auth = getAuth();
      const result = await signInWithPopup(auth, provider)
      // This gives you a Google Access Token. You can use it to access the Google API.
      GoogleAuthProvider.credentialFromResult(result);
      // Registration Check
      const user = result.user;
      const db = getFirestore();
      
      // Firestore users
      const usersRef = collection(db, "users");
      const usersQuery = query(usersRef, where("email", "==", user.email), limit(1));
      const usersQuerySnap = await getDocs(usersQuery);

      //Firestore locations
      const locationsRef = collection(db, "locations");
      const locationsQuery = query(locationsRef, where("owner", "==", user.uid), limit(1));
      const locationsQuerySnap = await getDocs(locationsQuery);

      try {
        if (usersQuerySnap.size === 0) {
          // User is not registered, so sign them out
          await signOut(auth);
          console.log('User is not registered');
          // !!! ATTENTION put a modal here to ask users to contact FusorLabs to discuss a tailored solution for their business
        } 
        
        else {
          // User is registered, so continue
          console.log('User is registered');
          isRegistered.value = true;

          // Getting user locations
          if (locationsQuerySnap.size != 0){
            locationsQuerySnap.forEach( async (doc) => {
              const locationId = doc.id;
              const locationName = doc.data().name;
              stateStore.appendToUserLocations(locationName, locationId);
            });
          }

          //Moving to next page
          if(await checkAdmin()){
            console.log("NavBar: Admin logging in...")
            router.replace({ name: 'AdminHome' });
          }
          else {
            console.log("NavBar: User logging in...")
            router.replace({ name: 'Inbox' });            
          }

        }
      }
      catch (error) {
        console.log(error);
      }
    }

    const logOut = () => {
      console.log("logging out")
      stateStore.clearStore();
      signOut(firebaseAuth).then(() => {
        router.replace({ name: "Home" })        
        }
      );
    }

    const register = () => {
      router.replace({ name: "Register" })
    }

    const changeLocation = (location) => {
      if (location) {
        stateStore.selectedLocation = location;              
      }

    }

    onMounted(() => {
      firebaseAuth.onAuthStateChanged((user) => {
        currUser.value = user;
      });

      dropdownItems.value = Object.keys(stateStore.userLocations);
    });

    return { 
      currUser, 
      isRegistered,
      signIn,
      logOut,
      register,
      loggedIn,
      route,
      changeLocation,
      dropdownItems,
    }
  },
};

</script>