<template>
  <div class="flex flex-col justify-center items-center">
    <div v-for="(date, index) in dates" :key="index">

      <!-- <div @click="toggleExpanded(index)">{{ date }}</div> -->
      <!-- <div>{{ date }}</div> -->
      <transition name="fade">
        <!-- <div v-if="expandedIndex === index"> -->
        <div>
          <VideoGrid 
            :path="paths[index]" 
            :width="3072/5" 
            :height="1728/5"
            :mode="mode"
            :moveFile="moveFile"/>
        </div>
      </transition>

    </div>

    <button @click="getNextPage"
            v-if="!loading && !fullyLoaded"
            class="btn-primary mt-10 mb-10"> 
      Load more 
    </button>
    <button v-else-if="loading"
        class="btn-primary mt-10 mb-10">
      Loading...
    </button>
    <button v-else
        class="btn-primary mt-10 mb-10">
      No more videos
    </button>      
  </div>
</template>

<script>
import VideoGrid from "@/components/VideoGrid";

import { ref, onMounted, } from "vue";
// import { firebaseAuth } from "@/firebase";
import { getStorage, ref as ref_storage, getDownloadURL, uploadBytes, deleteObject, list} from "firebase/storage";
import { useStateStore } from '@/stores/StateStore';

export default {
  props: ['mode'],
  components: { 
    VideoGrid
  },

  setup(props) {
    const stateStore = useStateStore();
    const selectedLocationId = ref(); // stateStore.userLocations["Chatswood Plus Fitness"] //locations[stateStore.selectedLocation];
    const listPath = ref();
    
    selectedLocationId.value = stateStore.userLocations[stateStore.selectedLocation];

    const confirmedPath = "clips/" + selectedLocationId.value + "-client-generated/confirmed/";
    const inboxPath = "clips/" + selectedLocationId.value + "-server-generated/" + selectedLocationId.value +"/inbox/";
    if(props.mode == "confirmed"){
      listPath.value = "gs://tailgating-detection.appspot.com/" + confirmedPath;
    }
    else if (props.mode == "inbox"){
      listPath.value = "gs://tailgating-detection.appspot.com/" + inboxPath;
    }

    const page = ref();
    const storage = getStorage();
    const listRef = ref_storage(storage, listPath.value);
    const maxResults = 20
    const dates = ref([]), paths = ref([])
    const loading = ref(false);
    const fullyLoaded = ref(false);

    const setupDates = async () => {
      page.value = list(listRef, { maxResults: maxResults });
      
      page.value.then( (res) => {
                        res.prefixes.forEach((folderRef) => {
                          const pathSplit = folderRef.fullPath.split("/")
                          const dateString = pathSplit[pathSplit.length - 1]
                          dates.value.push(dateString);
                          paths.value.push(listPath.value + dateString);
                        });
                      }
      )
    }    

    const getNextPage = async () => {
      loading.value = true;
      if ((await page.value).nextPageToken){
        page.value = list(listRef, { maxResults: maxResults, pageToken: (await page.value).nextPageToken });
        
        page.value.then( (res) => { res.prefixes.forEach((folderRef) => {
                              const pathSplit = folderRef.fullPath.split("/")
                              const dateString = pathSplit[pathSplit.length - 1]
                              dates.value.push(dateString);
                              paths.value.push(listPath.value + dateString);
                            });
                            loading.value = false;
                          }
                        )
      }
      else {
        loading.value = false;
        fullyLoaded.value = true;
      }
    }

    const moveFile = (vidPath, type) => {
      const storage = getStorage();
      const oldRefPath = vidPath;
      const oldRef = ref_storage(storage, oldRefPath);
      // const newRefPath = oldRefPath.replace("inbox", type);
      
      let newRefPath = null;
      if (type == "confirmed"){
        newRefPath = confirmedPath + (vidPath.split("/").slice(-2).join("/"))
      }
      
      else if (type == "false_positive") {
        newRefPath = confirmedPath.replace("confirmed", type) + (vidPath.split("/").slice(-2).join("/"))
      }

      console.log(type, newRefPath)
      const newRef = ref_storage(storage, newRefPath);

      getDownloadURL(oldRef)
        .then((url) => {
          // `url` is the download URL
          // This can be downloaded directly:
          const xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = () => {
            // Download the file
            const blob = xhr.response;

            // Upload the file to the new directory
            uploadBytes(newRef, blob).then(() => {
              console.log('Uploaded a blob or file!');

              // Delete the file
              deleteObject(oldRef).then(() => {
                // File deleted successfully
              }).catch((error) => {
                // Uh-oh, an error occurred!
                console.log('Deleting old file failed', error)
              });
              
              console.log('File moved successfully!');
            });

          };
          xhr.open('GET', url);
          xhr.send();

        })
        .catch((error) => {
          // Handle any errors
          console.error('Error getting download URL:', error);
        });
    }

    const expandedIndex = ref(0);
    const toggleExpanded = (index) => {
      expandedIndex.value = expandedIndex.value === index ? null : index;
    };

    onMounted(() => {
      setupDates();
    });

    return {
      setupDates,
      getNextPage,
      dates,
      expandedIndex,
      toggleExpanded,  
      listPath,
      paths,
      moveFile,
      loading,
      fullyLoaded,
    }
  }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>