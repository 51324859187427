<template>
  <div>
    <AdminNavBar />
    <div class="bg-theme-tertiary mx-5">
      <h1 class="font-bold font-serif text-5xl">Admin page</h1>

      <section class="mt-6">
        <p class="font-semibold text-xl underline text-gray-400">Get User details</p>
        <div class="flex">
          <form class="mx-1 my-3">
            <input class="text-black mx-3" type="email" placeholder="User Email" v-model="userEmail" required/>
          </form>
          <button 
              @click="connectUserLocation"
              class="btn-primary my-1">
              Get Details
          </button>
        </div>
        <p>uid: </p>
        <p>Payment status: </p>
        <p>Locations: </p>      
      </section>

      <section class="mt-6">
        <p class="font-semibold text-xl underline text-gray-400">Initialize Location</p>
        <p>linkcode, name, owner, zones</p>
        <div class="flex">
          <form class="mx-1 my-3">
            <input class="text-black mx-3" type="text" placeholder="name" v-model="zone" required/>
          </form>
          <button 
              @click="addZone"
              class="btn-primary my-1">
              Add
          </button>
        </div>
        <p>Link/ Registration code: </p>
        <p>Note: check for duplicate names. Maybe use address as identifier?</p>   
      </section>

      <section class="mt-6">
        <p class="font-semibold text-xl underline text-gray-400">Create registration code for new user</p>
        <div class="flex">
          <form class="mx-3 my-3">
            <input class="text-black" type="text" placeholder="Enter Location ID" v-model="locationId" required/>
          </form>
          <button 
              @click="createCode"
              class="btn-primary my-1">
              Create Linking Code
          </button>
        </div>
        <p class="text-red-500">{{ locationId }}</p>        
      </section>

      <section class="mt-6">
        <p class="font-semibold text-xl underline text-gray-400">Link users to locations</p>
        <div class="flex">
          <form class="mx-1 my-3">
            <input class="text-black mx-3" type="email" placeholder="User Email" v-model="userEmail" required/>
            <input class="text-black mx-3" type="text" placeholder="Enter Location ID" v-model="locationId" required/>
          </form>
          <button 
              @click="connectUserLocation"
              class="btn-primary my-1">
              Connect User and Location
          </button>
        </div>        
      </section>



      <section class="mt-6">
        <p class="font-semibold text-xl underline text-gray-400">Add input for camera areas for each location</p>
        <div class="flex">
          <form class="mx-1 my-3">
            <input class="text-black mx-3" type="email" placeholder="Areas for zone" v-model="zone" required/>
          </form>
          <button 
              @click="addZone"
              class="btn-primary my-1">
              Add
          </button>
        </div>        
      </section>


    </div>
  </div>
</template>

<script>
import AdminNavBar from "@/components/AdminNavBar";
import { ref } from 'vue';

export default {
  components: { 
    AdminNavBar 
  },
  setup() {
    const linkingCode = ref();
    const userEmail = ref();
    const locationId = ref();
    const zone = ref();

    const createCode = () => {
        console.log(locationId.value);
    }

    const connectUserLocation = () => {
        console.log("Linking user ", userEmail.value, " to location ", locationId.value);
    }

    const addZone = () => {
      console.log(zone.value)
    }

    return {
      linkingCode,
      userEmail,
      locationId,
      createCode,   
      connectUserLocation,
      addZone,
      zone
    }
  }
};
</script>