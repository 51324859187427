<template>
  <default-layout>
    <div v-if="isLoading">Loading account information...</div>
    <PriceSelection v-else-if="!subscription" />
    <SubscribedAccount v-else :subscription="subscription"/>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout";
import PriceSelection from "@/components/PriceSelection";
import SubscribedAccount from "@/components/SubscribedAccount";
import { ref, onMounted } from "vue";
import { firebaseAuth } from "@/firebase";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";

export default {
  components: {
    DefaultLayout,
    PriceSelection,
    SubscribedAccount
  },
  setup() {
    const isLoading = ref(false);
    const subscription = ref(null);

    const fetchSubscription = async () => {
      isLoading.value = true;
      const db = getFirestore();
      const subsRef = collection(
        db,
        "customers",
        firebaseAuth.currentUser.uid,
        "subscriptions"
      );
      const subsQuery = query(
        subsRef,
        where("status", "in", ["trialing", "active", "past_due", "unpaid"])
      );
      subscription.value = await getDocs(subsQuery).then((sub) =>
        sub.docs.length > 0 ? sub.docs[0].data() : null
      );
      isLoading.value = false;
    };

    onMounted(() => {
      fetchSubscription();
    });

    return {
      isLoading,
      subscription
    };
  }
};
</script>