<template>
  <header class="sticky top-0 bg-theme-primary shadow-lg">
    <nav
      class="flex flex-col sm:flex-row items-center gap-11 text-white py-6 m-0 p-16 bg-gradient-to-r from-purple-600 via-blue-400 to-orange-400"
    >
      <div class="block mt-4 lg:inline-block lg:mt-0 text-white mr-4 hover:text-teal-200">
        <RouterLink :to="{ name: 'Home' }">
          <div class="flex items-center gap-3 justify-start">
            <p @click="logOut" class="text-5xl font-semibold font-serif">Fusor Labs</p>
          </div>
        </RouterLink>
      </div>

      <div  :class="{ 'text-teal-200 font-bold text-xl border-b border-teal-200': route == '/account'}"
            class="block mt-4 lg:inline-block lg:mt-0 text-white mr-4 hover:text-teal-200">
        <RouterLink :to="{ name: 'AdminHome' }">
          <div class="flex items-center gap-3">
            <p class="text-l">Admin</p>
          </div>
        </RouterLink>        
      </div>
      

      <div class="flex gap-3 flex-1 justify-end">        
        <button v-if="!loggedIn"
                class="bg-transparent hover:text-teal-200 text-blue-700 font-semibold py-2 px-4 border border-blue-500 hover:border-teal-200 rounded"
                @click="signIn">
          Sign In
        </button>

        <button v-if="loggedIn"
                class="bg-transparent hover:text-teal-200 text-blue-700 font-semibold py-2 px-4 border border-blue-500 hover:border-teal-200 rounded" 
                @click="logOut">
          Log out
        </button>
      </div>
    </nav>
  </header>
</template>

<script>
import { 
          signOut, 
          // getAuth, 
          // GoogleAuthProvider, 
          // signInWithPopup
} from 'firebase/auth'
// import {
//   collection,
//   getFirestore,
//   getDocs,
//   where,
//   query,
//   limit,
// } from "firebase/firestore";
import { firebaseAuth, } from "@/firebase";
import { ref, onMounted,} from 'vue';
import { useRouter, useRoute} from 'vue-router'

export default {
  components: {

  },
  setup() {
    const router = useRouter()
    const route = ref();
    route.value = useRoute().path

    const currUser = ref(firebaseAuth.currentUser);
    const loggedIn = ref(false);
    loggedIn.value = currUser.value !== null ? true : false;


    const logOut = () => {
      console.log("logging out")
      signOut(firebaseAuth).then(() => {
        router.replace({ name: "Home" })        
        }
      );
    }

    onMounted(() => {
      firebaseAuth.onAuthStateChanged((user) => {
        currUser.value = user;
      });
    });

    return { 
      currUser,
      logOut,
      loggedIn,
      route,
    }
  },
};

</script>