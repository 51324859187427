<template>
  <default-layout>
    <h6>Tip: Right click video and select "Copy video link/address" or "Email video" to share video</h6>
    <br>
    <video-feed mode="confirmed"></video-feed>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout";
import VideoFeed from "@/components/VideoFeed";

export default {
  components: { 
    DefaultLayout,
    VideoFeed
  },

  setup() {
 
    return {

    }
  }
};
</script>