<template>
  <div class="relative">
    <select v-model="selectedOption"
            @change="onChange"
            class="block appearance-none w-full text-black bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
      <option disabled value="">Select a location</option>
      <option v-for="(item, index) in items"
          :value="item"
          :key="index"
      >
          {{ item }}
      </option>
    </select>
    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
      <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M14.707 7.293a1 1 0 0 0-1.414 0L10 10.586 6.707 7.293a1 1 0 1 0-1.414 1.414l3 3a1 1 0 0 0 1.414 0l3-3a1 1 0 0 0 0-1.414z"/></svg>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watchEffect } from 'vue';

export default {
  props: ['items', 'handleSelect'],
  setup(props) {
    // "Chatswood Plus Fitness"
    const selectedOption = ref();

    const onChange = () => {
      selectedOption.value = props.items[0]
      // props.handleSelect(event.target.value) // event was the parameter of this function
      props.handleSelect(selectedOption.value)
    }

    onMounted(() => {
      props.handleSelect(selectedOption.value)
    })

    watchEffect(() => {
      selectedOption.value = props.items[0]
      props.handleSelect(selectedOption.value)
    })
    return {
      onChange,
      selectedOption,
    };
  },
};
</script>