<template>
  <div class="bg-theme-tertiary">
    <NavBar/>
    <h1>Registration Page</h1>
    <br />
    <p>A sign in pop up will appear after the correct registration code is submitted</p>
    <input
      class="text-black"
      type="text"
      placeholder="Enter your registration code"
      v-model="code"
    />
    <button 
      class="btn-primary"
      @click="handleClick">
      Submit
    </button>
    <br />
    <b class="text-red-500">{{ message }}</b>
    <br />
    <p>Don't have a registration code? Contact us for pricing and solutions for your business</p>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import { ref } from 'vue';
import {getAuth, GoogleAuthProvider, signInWithPopup} from 'firebase/auth'
import {
  collection,
  getFirestore,
  getDocs,
  where,
  query,
  limit,
} from "firebase/firestore";
import { useRouter } from 'vue-router'

export default {
  components: { 
    NavBar 
  },
  setup() {
    const allowSignIn = ref(false);
    const message = ref('');
    const code = ref('');
    let submittedCode = '';

    const handleClick = async() => {
      if (code.value !== ""){
        submittedCode = code.value;
        const db = getFirestore();
        const locationsRef = collection(db, "locations");
        const locationsQuery = query(locationsRef, where("linkcode", "==", submittedCode), limit(1));
        const locationsQuerySnap = await getDocs(locationsQuery);
        try {
          if (locationsQuerySnap.size === 0) {
            console.log('Invalid Registration code');
            message.value = 'Invalid Registration code';
            allowSignIn.value = false;
          } 
          
          else {
            console.log('Registration code is valid');
            message.value = 'Sucess! Opening sign in popup...';
            allowSignIn.value = true;
            signIn();
          }
        }
        catch (error) {
          console.log(error);
        }        
      }
      
      else {
        message.value = 'Please enter a Registration code';
        allowSignIn.value = false;
      }

    }

    const signIn = async () => {
      const provider = new GoogleAuthProvider()
      const auth = getAuth();

      return await signInWithPopup(auth, provider)
        .then(() => useRouter().replace({ name: 'Inbox' }))
    }

    return {
      code,
      message,
      handleClick,
      allowSignIn,
    };
  },
};
</script>