<template>
  <div class="row">
    <div class="col">
      
      <h2>Choose a Plan:</h2>
      
      <hr />

      <div v-for="(product, index) in products" 
            class="py-5"
            :key="index + '-product'">

        <div
          v-for="(price, priceIndex) in product.prices"
          :key="priceIndex + '-price'">
          <div class="form-check">

            <input
              type="radio"
              class="form-check-input"
              v-model="selectedPrice"
              :value="price.id"
              :id="price.id" />
            
            <label :for="price.id" class="form-check-label">
              {{ product.name }}: ${{ price.unit_amount/100 }}/{{ price.interval }}
            </label>

          </div>
        </div>
      </div>

      <button
        class="btn-primary"
        @click="createSub"
        :disabled="!selectedPrice || isLoading"
      >
        {{ isLoading ? "Loading..." : "Create subscription" }}
      </button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import {
  getFirestore,
  getDocs,
  where,
  query,
  collection,
  addDoc,
  onSnapshot,
} from "firebase/firestore";
import { firebaseAuth } from "@/firebase";

export default {
  setup() {
    const products = ref([]);
    const selectedPrice = ref(null);
    const isLoading = ref(false);

    const fetchProducts = async () => {
      const db = getFirestore();
      const productsRef = collection(db, "products");
      const productsQuery = query(productsRef, where("active", "==", true));
      const productsQuerySnap = await getDocs(productsQuery);
      productsQuerySnap.forEach(async (doc) => {
        const pricesRef = collection(db, "products", doc.id, "prices");
        const pricesQuerySnap = await getDocs(pricesRef);
        products.value.push({
          id: doc.id,
          ...doc.data(),
          prices: pricesQuerySnap.docs.map((price) => {
            return {
              id: price.id,
              ...price.data(),
            };
          }),
        });
      });
    };

    onMounted(() => {
      fetchProducts();
    });

    const createSub = async () => {
      isLoading.value = true;
      const db = getFirestore();
      const collectionRef = collection(
        db,
        "customers",
        firebaseAuth.currentUser.uid,
        "checkout_sessions"
      );
      const docRef = await addDoc(collectionRef, {
        price: selectedPrice.value,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      });
      onSnapshot(docRef, (snap) => {
        const { error, url } = snap.data();
        if (error) {
          console.error(`An error occured: ${error.message}`);
          isLoading.value = false;
        }
        if (url) {
          window.location.assign(url);
        }
      });
    };


    return {
      products,
      selectedPrice,
      isLoading,
      fetchProducts,
      createSub
    }
  },
};
</script>